
@font-face {
  font-family: "kaixi";
  src: url(/src/img/DIN-Bold.otf)
}
.login{
    width: 100vw;
    /* background-color: #F5F5F5; */
    height: 100vh;
    background-position:center center;
    background-size:cover;
    display: flex;
    align-items: center;
    justify-content: center;
    }
 .login_img1{
  /* width: 330px; */
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -100px;
 }

 .login_img1 img{
   height: 100%;
 }

 .login_img2{
  width: 296px;
  height: 10px;
  margin-top: 30px;
  background-position:center center;
  background-size:cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
 }

 .login_img2-k{
  width: 296px;
  height: 10px;
  background: rgba(250, 99, 44, 0.3);
 }

 .login_img2 img{
   width: 296px;
  height: 10px;
  

 }

 .login_dd{
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  /* align-items: center;
  justify-content: center; */
  transition: width 1.5s;
  overflow: hidden;
 }

 /* 第一页 */
 .index{
  width: 100vw;
  /* background-color: #F5F5F5; */
  height: 100vh;
  background-position:center center;
  background-size:cover;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  position: relative;
  }
.index_img1{
height: 34px;
display: flex;
align-items: center;
justify-content: center;
position: absolute;
top: 26px;
left: 26px;
}

.index_img1 img{
 height: 100%;
}

.index_img2{
width: 94%;
margin-top: -100px;
}

.index_img2 img{
  width: 100%;
 }

.index_img2-k{
height: 10px;
background: #FA632C;
}


.index_img3{
height: 110px;
}

.index_img3 img{
 height: 100%;
}

.index_con{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 30%;
}

/* 地图 */

.map{
  width: 100vw;
  /* background-color: #F5F5F5; */
  height: 100vh;
  background-position:center center;
  background-size:contain;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  position: relative;
  }
.map_img1{
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 26px;
  left: 26px;
}

.map_img1 img{
 height: 100%;
}

.map_img2{
width: 100%;
display: flex;
align-items: center;
justify-content: center;
 position: absolute;
 bottom: 0;
}

.map_img2 img{
  width: 100%;
 }

.map_img2-k{
height: 10px;
background: #FA632C;
}


.map_tos{
  width: 100vw;
color:#FA632C ;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 28px;
}


/* 效果 */
.excessive{
  width: 100vw;
  /* background-color: #F5F5F5; */
  height: 100vh;
  background-position:center center;
  background-size:cover;
  display: flex;
  align-items: center;
  justify-content: center;
  }

  .excessive img{
      width: 100%;
      height: 100%;
      object-fit:cover;
      opacity:0.56;
      }



/* 结果 */
.detail{
  width: 100vw;
  /* background-color: #F5F5F5; */
  height: 100vh;
  background-position:center center;
  background-size:cover;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  position: relative;
  }

  .detail_bg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .detail_img1{
    height: 34px;
    display: flex;
    align-items: center;
     padding-top: 26px;
    padding-left: 26px;
  }
  
  .detail_img1 img{
   height: 100%;
  }


.detail_tost{
  background: rgba(0, 0, 0,0.9);
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  position: fixed;
  top: 0;
}

.detail-video{
  position: fixed;
  top: 25%;
  z-index: 999;
}


.detail_img2{
width: 90%;
display: flex;
align-items: center;
justify-content: center;
position: relative;
}

.detail_look{
  position: absolute;
  top: 45%;
  left: 43%;
  width: 50px;
}

.detail_img1t{
  width: 100%;
 }

.detail_img2-k{
height: 10px;
background: #FA632C;
}


.detail_img3{
width: 92%;
}

.detail_img3 img{
 width: 100%;
}

.detail_con{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.detail_gl{
  width: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail_gl img{
  width: 100%;
 }

.detail_hb{
  width: 200px;
  position: absolute;
  bottom: 6%;
  left: 4%;
}

.detail_hb img{
  width: 100%;
 }

.detail_ren{
  width: 110px;
  height: 110px;
  background-position:center center;
  background-size:cover;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  position: absolute;
  bottom: 2%;
  right: 4%;
}

 .detail_text{
   margin-left: 4%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
 }

 .detail_num{
  font-size: 60px;
  font-family: "kaixi";
  /* font-family: DIN Black; */
  font-weight: 400;
  color: #FA632C;
 }

 .detail_zz{
   position: relative;
   z-index: 999;
   position: fixed;
   left: 0;
   top: 0;
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100vh;
   width: 100vw;
   background: rgba(8, 94, 120,0.78);
 }
 
 .detail_gb{
   position: absolute;
   top: 50px;
   right: 50px;
   width: 30px;
   height: 30px;
 }

 .detail_ewm{
  position: absolute;
  bottom: 6%;
  left: 6%;
   width: 100px;
   height: 100px;
 }

 .detail_ewm img{
  width: 100px;
  height: 100px;
}
.detail_baocun{
  position: absolute;
  bottom: 6%;
  width: 50vw;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  /* flex-direction: column; */
}

.detail_yh{
  margin-top: 2px;
  width: 55px;
  height: 55px;
}

.detail_xmg{
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  width: 100%;
  height: 22px;
  text-align: left;
  margin-left: 20px;
  margin-top: 2px;
  color: #FFFFFF;
}


.detail_loding{
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0,0.78);
  color: #FA632C;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box{
  width:300px;
  height:300px;
  background-color: pink;
}

